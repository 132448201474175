<template>
  <div class="cart-payment">
    <div class="cart-review-title">
      <h1 class="page-title">Select your payment method</h1>
      <CartSteps step="payment_method" />
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <b-form-group v-if="loggedIn">
          <!-- label="Select your payment method:" -->
          <b-form-radio-group
            :disabled="cartTotalWithFee == 0"
            v-model="form.payment_method"
            @change="busy = false"
          >
            <b-form-radio value="credit_card">
              <div class="payment-icons">
                <MasterIcon />
                <VisaIcon />
              </div>
              <span>Card</span>
            </b-form-radio>
            <div
              class="radio-content"
              v-if="form.payment_method == 'credit_card'"
            >
              <stripe-element-card
                ref="stripeElementCard"
                :pk="stripePublicKey"
                @element="tokenCreated"
              />
            </div>

            <b-form-radio value="paypal">
              <div class="payment-icons">
                <PaypalIcon />
              </div>
              <span>Paypal</span>
            </b-form-radio>
            <div class="radio-content" v-if="form.payment_method == 'paypal'">
              <!-- Paypal charges {{ paypalFee }}% fee to make this transaction. -->
              <b-alert v-model="paypalError" variant="danger">
                <strong>
                  {{ paypalErrorMessage }}
                </strong>
              </b-alert>
              <div
                v-if="!paypalError"
                id="paypalButtonContainer"
                ref="paypalButtonContainer"
              />
            </div>

            <b-form-radio value="klarna" v-if="false">
              <div class="payment-icons">
                <KlarnaIcon />
              </div>
              <span>Klarna</span>
            </b-form-radio>
            <div class="radio-content" v-if="form.payment_method == 'klarna'">
              Pay with Klarna {{ klarnaFee }}% fee
              <!--<b-alert v-model="paypalError" variant="danger">
                <strong>
                  {{ paypalErrorMessage }}
                </strong>
              </b-alert>
              <div v-if="!paypalError" id="paypalButtonContainer" ref="paypalButtonContainer"></div>-->
              <div id="klarnaButtonContainer" ref="klarnaButtonContainer"></div>
            </div>
          </b-form-radio-group>
        </b-form-group>
        <b-btn variant="primary" block to="/login" v-else>
          Log In AND CHECK OUT
        </b-btn>
      </div>
      <div class="col-12 col-md-4">
        <div class="cart-sidebar">
          <div>
            <b-form-checkbox
              v-model="has_coupon"
              :unchecked-value="false"
            >
              <p class="mb-0">Do you have a coupon?</p>
            </b-form-checkbox>

            <b-form @submit.stop.prevent="validateCoupon" v-if="has_coupon" class="mt-2">
            <b-form-group>
              <b-input-group>
                <b-form-input :class="[{'valid-coupon': coupon.valid }]" :disabled="coupon.valid" v-model="code_coupon" placeholder="Inform your coupon" type="text"/>
                <b-button v-if="!coupon.valid" variant="outline-primary" type="submit" size="sm">Validate</b-button>
              </b-input-group>
            </b-form-group>
            </b-form>

          </div>
          <div class="sidebar-widget">
            <b-overlay
              :show="busy"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-btn
                variant="primary"
                block
                :disabled="(form.payment_method == '' || busy) && cartTotalWithFee != 0"
                @click="handlePayment()"
              >
                {{ cartTotalWithFee == 0 ? "order with free coupon" : "Pay Order"}}
              </b-btn>
            </b-overlay>

            <div class="sidebar-total">
              <p>
                Order Total
                <span>
                  {{ cartTotalWithFee | currency }}
                </span>
              </p>
            </div>
          </div>
          <div
            class="sidebar-widget product-info"
            v-for="product in this.cart"
            :key="product.id"
          >
            <ul class="list-unstyled">
              <li>
                <p>{{ product.quantity }} x {{ product.name }}</p>
                <span>{{ getProductPrice(product) | currency }}</span>
              </li>

              <li
                class="info-small"
                v-for="ticket in product.tickets"
                :key="ticket.id"
              >
                <p>{{ ticket.ticket_name }}</p>
                <span v-if="false">COVID test € 10,00</span>
              </li>

              <li class="mb-1" v-if="coupon.discount_amount > 0">
                <p>Coupon discount</p>
                <span> - € {{ new Intl.NumberFormat('de-DE').format(coupon.discount_amount) }}</span>
              </li>

              <li class="info-small" v-if="product.vat > 0">
                <p>Tax {{ product.vat }}%</p>
                <span>€ {{ new Intl.NumberFormat('de-DE').format(getVatPrice(product)) }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadScript, loadCustomScript } from "@paypal/paypal-js";

import OrderService from "@/services/order.service";

import Toast from "@/mixin/toast";
import CartSteps from "@/components/CartSteps.vue";
import PaypalIcon from "@/assets/icons/icon-payment-paypal.svg";
import MasterIcon from "@/assets/icons/icon-payment-master.svg";
import VisaIcon from "@/assets/icons/icon-payment-visa.svg";
import KlarnaIcon from "@/assets/icons/klarna.svg";
import StripeElementCard from "@/components/elements/Card";

export default {
  name: "CartPayment",
  metaInfo: {
    title: "Select your payment method",
  },
  mixins: [Toast],
  components: {
    CartSteps,
    PaypalIcon,
    StripeElementCard,
    MasterIcon,
    VisaIcon,
    KlarnaIcon,
  },
  data() {
    return {
      token: "",
      total: 0,
      cardFee: 3,
      paypalFee: 5,
      klarnaFee: 3,
      payment_methods: [],
      has_coupon: false,
      code_coupon: "",
      coupon: {},
      form: {
        payment_method: "",
      },
      cc: {
        name: "",
        number: "",
        expiration: "",
        cvv: "",
      },
      busy: false,
      valid_card: false,
      paypalError: false,
      paypalErrorMessage: "",
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    cartTotal: {
      cache: false,
      get() {
        return this.$store.getters.cart.reduce(
          (prev, cur) => prev + (cur.total + this.getVatPrice(cur) * cur.quantity),
          0
        ) - (this.coupon.discount_amount || 0);
      },
    },
    stripePublicKey() {
      return process.env.VUE_APP_PUBLIC_KEY_STRIPE
    },
    loggedIn() {
      return this.$store.getters["AuthModule/loggedIn"];
    },
    cart() {
      return this.$store.getters.cart;
    },
    paymentFee() {
      if (this.form.payment_method == "credit_card") {
        return ((this.total * this.cardFee) / 100).toFixed(2);
      } else if (this.form.payment_method == "paypal") {
        return ((this.total * this.paypalFee) / 100).toFixed(2);
      } else {
        return 0;
      }
    },
    cartTotalWithFee: {
      cache: false,
      get() {
        return Number(this.cartTotal);
      },
    },
  },
  methods: {
    initialize() {
      if(this.$store.getters.coupon) {
        this.has_coupon = true;
        this.coupon = this.$store.getters.coupon;
        this.code_coupon = this.$store.getters.coupon.code;
      }

      this.total = this.cartTotal;

      if (this.cartTotalWithFee == 0 && this.coupon.id) {
        this.form.payment_method = "free_coupon"
      }
    },
    async validateCoupon () {
      try {
        if (this.cart.length > 1 || (this.cart.length > 0 && this.cart[0].tickets.length > 1)) {
          this.showToast("danger", "Error", "You can only have one ticket in your cart to use a coupon");

          return;
        }

        const response = await OrderService.validateCoupon(this.code_coupon, this.cart[0].id)

        if(response) {
          this.coupon = response.data
        } else {
          this.showToast("danger", "Error", 'Coupon invalid');
        }
        // this.coupon = response.data;
      } catch (error) {
        this.showToast("danger", "Error", 'Coupon invalid');
      }
    },
    // called by stripe after a credit card token generation
    async tokenCreated({ card, stripe }) {
      // console.log("tokenCreated", this.valid_card);
      this.valid_card = true;

      try {
        const { data } = await OrderService.create({
          cart: this.cart,
          payment_method: "credit_card",
          coupon_id: this.coupon.id || null,
        });

        if (data.errors) {
          this.showToast("danger", "Error", data.errors[0]);
          this.busy = false;

          return;
        }

        const payload = await stripe.confirmCardPayment(data.stripe_client_secret, {
          payment_method: { card },
        });

        if (payload.error) {
          this.showToast("danger", "Error", payload.error.message);
          this.busy = false;
        } else {
          await OrderService.stripeCapture(payload.paymentIntent.id);

          this.toastSuccess();
        }
      } catch (error) {
        this.busy = false;

        if (error.response.data.errors) {
          this.showToast("danger", "Error", error.response.data.errors[0]);
        }
      }
    },

    async handlePayment() {
      this.busy = true;

      switch (this.form.payment_method) {
        case "credit_card":
          this.$refs.stripeElementCard.submit();

          setTimeout(() => {
            if (this.valid_card === false) {
              this.busy = false;
            }
          }, 3000);
          break;

        case "paypal":
          this.paymentPaypal();
          break;

        case "klarna":
          this.paymentKlarna();
          break;
        case "free_coupon":
          this.paymentFreeCoupon()
          break;

        default:
          break;
      }
    },
    async paymentPaypal() {
      try {
        // const orderId = response.data.order_id;

        this.paypalError = false;
        let self = this;

        // https://developer.paypal.com/docs/business/javascript-sdk/javascript-sdk-reference/
        const paypal = await loadScript({
          "client-id": process.env.VUE_APP_CLIENT_ID_PAYPAL,
          currency: "EUR",
        });

        await paypal
          .Buttons({
            createOrder() {
              return OrderService.create({
                cart: self.cart,
                payment_method: "paypal",
                coupon_id: self.coupon.id || null,
              }).then((result) => {
                return result.data.paypal_token;
              })
            },
            async onApprove(data) {
              // https://developer.paypal.com/api/orders/v2/#orders_capture
              self.paypalError = true;
              try {
                const res = await OrderService.paypalCapture(data.orderID);
                self.toastSuccess();
                return res.data;

                // This function captures the funds from the transaction.
                // return actions.order.capture().then(function(details) {
                //   // This function shows a transaction success message to your buyer.
                //   // alert('Transaction completed by ' + details.payer.name.given_name);
                //   self.toastSuccess();
                // });

              } catch (error) {
                self.paypalError = true;
                self.paypalErrorMessage = error.response.data.errors[0];
                self.busy = false;
              }
            },
            onError(err) {
              self.paypalError = true;
              self.paypalErrorMessage = err.toString();
              if (err.toString().indexOf(`Error: Document is ready `) != -1) {
                self.paypalErrorMessage = `Please select another payment method or refresh the page to try again via Paypal`;
              }
              self.busy = false;
            },
            style: {
              color: "gold",
              shape: "rect",
              label: "buynow",
              size: "responsive",
              branding: true,
              layout: "horizontal",
            },
          })
          .render("#paypalButtonContainer");
      } catch (error) {
        if (error.reponse.data.errors[0]) {
          self.showToast("danger", "Error", error.reponse.data.errors[0]);
        }
        self.paypalError = true;
        self.busy = false;
      }
    },
    async paymentKlarna() {
      try {
        let response = await OrderService.create({
          cart: this.cart,
          payment_method: "klarna",
        });

        const orderId = response.data.order_id;

        let self = this;

        response = await OrderService.klarnaCreate(orderId);

        if (
          typeof response.data.status === "boolean" &&
          !response.data.status
        ) {
          if (response.data.errors[0] === "Bad value: billing_address.phone") {
            this.showToast("danger", "Error", "mobile informed is invalid");
          } else {
            this.showToast("danger", "Error", response.data.errors[0]);
          }

          self.busy = false;
          return;
        }

        const klarnaClientToken = response.data.id;

        loadCustomScript({
          url: "https://x.klarnacdn.net/kp/lib/v1/api.js",
        })
          .then(() => {
            window.Klarna.Payments.init({
              client_token: klarnaClientToken,
            });

            window.Klarna.Payments.load(
              {
                container: "#klarnaButtonContainer",
                payment_method_category: "pay_later",
              },
              function (res) {
                if (res.show_form) {
                  window.Klarna.Payments.authorize(
                    {
                      payment_method_category: "pay_later",
                    },
                    {
                      purchase_country: "DE",
                      purchase_currency: "EUR",
                      locale: "en-DE",
                    },
                    async function (authorizationResult) {
                      if (!authorizationResult.approved) {
                        self.busy = false;
                      } else if (authorizationResult.approved) {
                        await OrderService.klarnaCapture(
                          klarnaClientToken,
                          authorizationResult
                        )
                          .then(() => {
                            self.toastSuccess();
                          })
                          .catch((klarnaError) => {
                            console.log(klarnaError, klarnaError.response);
                          });
                      }
                    }
                  );
                }
              }
            );
          })
          .catch((err) => {
            console.error("failed to load the custom script", err);
          });
      } catch (error) {
        if (error.response.data.errors[0]) {
          // this.showToast('danger', 'Error', result.data.errors[0] + " check this on your profile and try again");
          this.showToast("danger", "Error", error.reponse.data.errors[0]);
        }
        this.paypalError = true;
        this.busy = false;
      }
    },
    async paymentFreeCoupon() {
      try {
        await OrderService.create({
          cart: this.cart,
          payment_method: "free_coupon",
          coupon_id: this.coupon.id,
        });

        this.toastSuccess()
      } catch (error) {
        console.error(error)
      }
    },
    toastSuccess() {
      this.showToast("success", "Pay with Success", "Check your email");
      this.$store.commit("updateCart", []);
      this.$store.commit("updateCoupon", null);

      setTimeout(() => {
        this.$router.push(`/user/orders`);
      }, 2000);
    },
    getProductPrice(product) {
      return product.price * product.quantity;
    },
    getVatPrice(product) {
      const price_item = this.coupon.discount_amount ? product.price - this.coupon.discount_amount : product.price

      return ((price_item / 100) * product.vat).toFixed(2);
    },
    getTotalVat(product) {
      const total = (product.price / 100) * product.vat * product.quantity;

      return total.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    },
  },
};
</script>

<style lang="scss">
.cart-payment {
  .cart-review-title {
    .page-title {
      margin: 6rem 0 3rem;
      font-size: 76px;
      font-weight: 700;
      text-align: center;

      @media screen and (max-width: 770px) {
        margin-top: 15px;
        line-height: 1.0;
      }
    }
  }

  .valid-coupon {
    border: 1px solid var(--brand-orange);
    background: rgba(255, 255, 255, .1);
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .custom-control-inline {
    display: flex;
    background: var(--whole-bg-lighter);
    width: 100%;
    align-items: center;

    &:not(:first-child) {
      margin-top: 2px;
    }

    label {
      display: flex;
      padding: 0.5rem 0;
      padding-left: 15px;
      width: 100%;

      .payment-icons {
        display: flex;
        width: 90px;
        justify-content: space-between;
        margin-right: 20px;
      }

      // radios
      &::before {
        top: 13px;
        left: -14px;
      }
      &::after {
        top: 13px;
        left: -14px;
      }
    }
  }

  .radio-content {
    background: var(--whole-black-form-bg);
    padding: 1rem;
  }
}
</style>
